import React from 'react';
import styled from 'styled-components';

//components
import DetailBox1 from './DetailBox1';
import DetailBox2 from './DetailBox2';
import Mockup from './Mockup';
//ui
import { Section } from '../../styles/ui';

const Container = styled.div`
  width: 100%;
`;
const Img = styled.img`
  width: 100%;
  padding-top: 5rem;
  @media (max-width: 1000px) {
    padding-top: 0;
  }
`;

export default function() {
  const data = {
    title: '밥정너',
    createdBy: '세얼간이',
    createdAt: '2017',
    url: 'https://bapjn-ceo.firebaseapp.com/',
    client: '세얼간이',
    detail:
      '사용자 위치기반으로 주변음식점 중 랜덤으로 선택되는 방식이며 사전에 각각의 음식점과 협약하여 발행된 쿠폰이 지급되는 방식의 모바일 어플리케이션입니다. 밥정너는 단순히 기능위주의 어플리케이션으로 인식되기 보단 결정장애를 위한 랜덤선택이라는 요소를 사용하여 재미요소를 넣어 사용자들의 머릿속에 계속 생각나는 어플리케이션입니다.',
    role: ['UI / UX', 'Webdesign', 'Brand identity', 'Development', 'Server'],
    displayImg: require('./img/display_bapjeongneo.jpg'),
    PM: 'Junhyeok Heo/ Seungyeon Kim/ Namgon Lee',
    detailImg: require('./img/bapjn_detail.jpg')
  };

  return (
    <Container>
      <DetailBox1
        title={data.title}
        client={data.client}
        detail={data.detail}
        role={data.role}
        url={data.url}
      />
      <Mockup
        mockup={data.mockup}
        img={data.displayImg}
        containerStyle={{ backgroundColor: '#f6d155' }}
      />
      <Section>
        <Img src={data.detailImg} />
      </Section>
      <DetailBox2 url={data.url} PM={data.PM} createdAt={data.createdAt} />
    </Container>
  );
}
