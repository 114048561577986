import React from 'react';

import styled from 'styled-components';

// common
import LineBreak from '../../common/LineBreak';
import { ReactComponent as Arrow } from '../../common/svg/arrow_right.svg';

//ui
import { Section } from '../../styles/ui';

const SectionBox = styled(Section)`
  display: flex;
  flex-direction: column;
  padding-top: 15rem;
`;
const Title = styled.div`
  ${props => props.theme.typo.h1};
  font-weight: 500;
  padding: 0 3rem 15rem 3rem;

  @media (max-width: 1000px) {
    padding-bottom: 5rem;
  }
`;
const DetailBox = styled.div`
  display: flex;
  padding: 0 3rem 15rem 3rem;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding-bottom: 5rem;
  }
`;
const SubTitle = styled.p`
  padding-bottom: 3rem;
  ${props => props.theme.typo.p4};
  color: ${props => props.theme.colors.GREY_LIGHT_4};
  border-bottom: 2px solid ${props => props.theme.colors.GREY_LIGHT_2};
`;
const Content = styled.div`
  ${props => props.theme.typo.p1};
  padding-top: 4rem;
  padding-right: 10rem;
  line-height: 2.5;
  list-style: none;

  @media (max-width: 1000px) {
    padding-top: 2rem;
    padding-right: 3rem;
  }
`;
const Row = styled.div`
  flex: ${props => props.flex};
  padding-bottom: 7rem;
`;
const Visit = styled.a`
  ${props => props.theme.typo.p1};
  color: ${props => props.theme.colors.GREY_DARK_3};
  display: flex;
  padding-top: 5rem;
  text-decoration: none;
  cursor: pointer;
  & span {
    padding-left: 1rem;
  }
  &:hover span {
    transition: all 0.3s ease-in-out;
    transform: translateX(1rem);
  }

  @media (max-width: 1000px) {
    padding-top: 2rem;
  }
`;

export default function({ title, client, detail, url, role }) {
  return (
    <SectionBox>
      <Title>{title}</Title>
      <DetailBox>
        <Row flex={1}>
          <SubTitle>CLIENT</SubTitle>
          <Content>{client}</Content>
        </Row>
        <Row flex={1.5}>
          <SubTitle>PROJECT</SubTitle>
          <Content>
            <LineBreak text={detail} />
          </Content>
          <Visit href={url}>
            Visit Site{' '}
            <span>
              <Arrow />
            </span>
          </Visit>
        </Row>
        <Row flex={0.8}>
          <SubTitle>ROLE</SubTitle>
          <Content>
            {role.map((r, i) => (
              <li key={i}>{r}</li>
            ))}
          </Content>
        </Row>
      </DetailBox>
    </SectionBox>
  );
}
