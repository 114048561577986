import React, { useRef } from 'react';
import styled from 'styled-components';

//ui
import { Section } from '../../styles/ui';
//hooks
import useReachedScrollTop from '../../hooks/useReachedScrollTop';

const images = [
  {
    name: 'react',
    src: require('./img/react_logo.png'),
    href: 'https://reactjs.org/'
  },
  {
    name: 'react_native',
    src: require('./img/react_native_logo.png'),
    href: 'https://facebook.github.io/react-native/'
  },
  {
    name: 'nodejs',
    src: require('./img/nodejs_logo.png'),
    href: 'https://nodejs.org/'
  },
  {
    name: 'firebase',
    src: require('./img/firebase_logo.png'),
    href: 'https://firebase.google.com/'
  },
  {
    name: 'google_cloud_platform',
    src: require('./img/google_cloud_platform_logo.png'),
    href: 'https://cloud.google.com/'
  },
  {
    name: 'styled_components',
    src: require('./img/styled_components_logo.png'),
    href: 'https://www.styled-components.com/'
  },
  {
    name: 'godaddy',
    src: require('./img/godaddy_logo.png'),
    href: 'https://godaddy.com'
  }
];

export default function Technologies() {
  const containerEl = useRef(null);
  const isReached = useReachedScrollTop(containerEl);

  return (
    <Container ref={containerEl}>
      <div>
        <Title>TECHNOLOGIES AND PARTNERS</Title>
        <Text isShow={isReached}>
          로이코드는 최신 기술 트렌드를 지향합니다. Facebook이 주도하는 오픈소스
          기술 리액트와 리액트 네이티브를 중점으로 사용합니다. 또한 구글의
          인프라를 이용하여 안정적인 서버를 구축합니다.
        </Text>
      </div>
      <ImgBox isShow={isReached}>
        {images.map((image, i) => (
          <a href={image.href} target="_black" key={i}>
            <Img src={image.src} alt={image.name} />
          </a>
        ))}
      </ImgBox>
    </Container>
  );
}

const Container = styled(Section)`
  display: flex;
  padding-top: 10rem;
  padding-bottom: 10rem;

  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`;
const Title = styled.div`
  ${props => props.theme.typo.p4};
  margin-bottom: 5rem;
  color: ${props => props.theme.colors.GREY_LIGHT_4};

  padding-left: 3rem;

  @media (max-width: 800px) {
    padding-right: 3rem;
  }
`;
const Text = styled.p`
  ${props => props.theme.mixins.changeTranslateY};
  ${props => props.theme.typo.p2};
  line-height: 2.5;
  max-width: 37rem;
  padding-bottom: 5rem;

  padding-left: 3rem;

  @media (max-width: 800px) {
    padding-right: 3rem;
  }
`;

const ImgBox = styled.div`
  ${props => props.theme.mixins.changeTranslateY};
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  align-self: center;

  max-width: 70rem;

  padding-right: 3rem;

  @media (max-width: 800px) {
    justify-content: center;
    align-self: auto;
    padding-right: 0;
  }
`;

const Img = styled.img`
  height: 8rem;
  filter: grayscale(100%);

  transition: all 0.4s;

  &:hover {
    filter: grayscale(0);
  }
`;
