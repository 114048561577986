import React from 'react';
import LoadingScreen from './LoadingScreen';

export default Component => props => {
  return (
    <>
      <Component {...props} />
      <LoadingScreen />
    </>
  );
};
