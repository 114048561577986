import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';

import { Provider as LoadingProvider } from './hoc/Loading';
import { Provider as MenuProvider } from './context/menu';

import firebase from 'firebase/app';
import * as serviceWorker from './serviceWorker';

const config = {
  apiKey: 'AIzaSyAE9LQ0YLC3iDJ5B6nGGR6srMz2zknYKdo',
  authDomain: 'loycordapp.firebaseapp.com',
  databaseURL: 'https://loycordapp.firebaseio.com',
  projectId: 'loycordapp',
  storageBucket: 'loycordapp.appspot.com',
  messagingSenderId: '532472188955'
};

firebase.initializeApp(config);
const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

ReactDOM.render(
  <LoadingProvider>
    <MenuProvider>
      <Root />
    </MenuProvider>
  </LoadingProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
