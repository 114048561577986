import React from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../styles/keyframes';

export default function Script() {
  return (
    <Container>
      <Title>
        당신이 상상하는
        <br />
        컨셉을 현실로
        <br />
        만듭니다.
      </Title>
      <Description>
        로이코드는 간단하면서도 강력한 아이디어로 설립되었습니다. 누구나
        아이디어를 통해 더 좋은 세상을 만들 수 있습니다.
      </Description>
      <Description>
        당신의 컨셉을 현실로 바꾸는 것이 우리의 일 입니다. 복잡한 문제들을
        심플한 솔루션으로 해결합니다. 빠르고 분명하게 아이디어를 현실에서
        확인하세요. "아이디어를 생각해 내고 아이디어를 실현한 후 버튼 하나로
        수백만 사람들이 사용할 수 있게 하는 것" 로이코드가 가장 중요하게
        생각하는 가치입니다.
      </Description>
      <Description>
        PC/Mobile Web, Android, iOS 등 모든 환경에서 당신의 비즈니스를
        로이코드와 함께 시작하세요.
      </Description>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  margin-top: 12rem;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 3rem;
  padding-right: 3rem;

  z-index: 10;
`;
const Title = styled.h1`
  ${props => props.theme.typo.h1};
  color: #fdfdfd;
  font-weight: 400;
  line-height: 1.2;

  animation: ${fadeIn} 1s;

  @media (max-width: 800px) {
    text-align: center;
  }
`;
const Description = styled.p`
  ${props => props.theme.typo.p1};
  color: #353535;
  line-height: 2;
  margin-top: 4rem;

  word-break: break-word;
  text-align: justify;
  font-weight: 200;

  animation: ${fadeIn} 1s;
`;
