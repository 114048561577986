import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './base';
import theme from './themes';

export default (Component: React.ComponentClass<any>): any => props => (
  <ThemeProvider theme={theme}>
    <>
      <Component {...props} />
      <GlobalStyle />
    </>
  </ThemeProvider>
);
