import React, { useState, useRef } from "react";
import styled from "styled-components";

//ui
import { Section } from "../styles/ui";
//hooks
import useReachedScrollTop from "../hooks/useReachedScrollTop";

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
`;
const Title = styled.div`
  ${(props) => props.theme.typo.p4};
  margin-bottom: 5rem;
  color: ${(props) => props.theme.colors.GREY_LIGHT_4};
  padding: 0 3rem;
`;
const MenuItem = styled.div`
  margin: 0 3rem;

  &:not(:last-child) {
    border-bottom: 2px solid ${(props) => props.theme.colors.GREY_LIGHT_2};
  }
`;
const MenuTitle = styled.div`
  ${(props) => props.theme.typo.h1};
  padding: 4rem 0;
  position: relative;
  display:flex;
  cursor: pointer;

  &::after {
    content: '${(props) => (props.on ? "-" : "+")}';
    position: absolute;
    top: 30%;
    right: 0.5rem;
  }

  @media (max-width: 600px) {
    ${(props) => props.theme.typo.h3};
  }
`;
const Text = styled.h1`
  ${(props) => props.theme.mixins.changeTranslateY};
  ${(props) => props.theme.typo.h1};
  color: "#333"
  font-weight: 400 !important;
`;
const MenuContentBox = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: all 0.5s;
  height: ${(props) => (props.on ? "25rem" : "0")};
  overflow: hidden;

  @media (max-width: 1000px) {
    justify-content: flex-start;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    height: ${(props) => (props.on ? "45rem" : "0")};
  }
`;
const ContentText = styled.p`
  ${(props) => props.theme.typo.p2};
  line-height: 2.5;
  max-width: 37rem;
  padding-bottom: 5rem;
`;
const ContentCategory = styled.ul`
  padding-left: 15%;
  list-style: none;
  @media (max-width: 600px) {
    padding-left: 0;
    padding-bottom: 3rem;
  }
`;
const CategoryItem = styled.li`
  ${(props) => props.theme.typo.h4};
  color: ${(props) => props.theme.colors.GREY_DARK_1};
  max-width: 18rem;
  padding: 0.7rem;
  margin-right: 5rem;
`;

function Strongness() {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const containerEl = useRef(null);
  const isReached = useReachedScrollTop(containerEl);

  const menuData = [
    {
      title: "개발",
      text:
        "로이코드는 최신 기술을 지향합니다. 다년간의 제작 노하우와 차별화된 기술력으로 최상의 웹/모바일 앱을 구축합니다. 또한 귀하의 사이트 혹은 앱 배포에 대한 상세한 설명을 통해 모든 과정을 이해하실 수 있도록 개발자와 1:1로 소통하여 안정적인 개발 서비스를 제공합니다.",
      categories: ["웹", "모바일(Android, iOS)", "호스팅", "도메인"],
    },
    {
      title: "MVP/프로토타입",
      text:
        "비즈니스를 시작하기에 앞서 시장에 대한 테스트를 검증하는 프로토타입을 전문적으로 제작합니다. 코드를 재활용하지 않습니다. 고객님만을 위한 빠르고 확장성 있는 고객님의 핵심적인 기능을 구축합니다.",
      categories: ["React", "React Native", "Firebase", "Node.js"],
    },
    {
      title: "디자인",
      text:
        "디자인은 비즈니스의 부분에서 강력한 역할을 합니다. 사용자의 요구와 세련된 디자인을 통해 긍정적인 사용자 경험을 구축합니다. 또한 고객님과의 지속적인 소통을 통해 완성도 높은 디자인을 완성합니다.",
      categories: ["웹 UI/UX", "모바일 UI/UX", "반응형 디자인", "로고 디자인"],
    },
  ];

  const renderMenu = menuData.map((menu, i) => {
    const renderCategory = menu.categories.map((category, j) => (
      <CategoryItem key={j}>{category}</CategoryItem>
    ));
    return (
      <MenuItem key={i}>
        <MenuTitle
          on={selectedIndex === i}
          onClick={() => {
            if (selectedIndex === i) {
              setSelectedIndex(-1);
            } else {
              setSelectedIndex(i);
            }
          }}
        >
          <Text isShow={isReached}>{menu.title}</Text>
        </MenuTitle>

        <MenuContentBox on={selectedIndex === i}>
          <ContentText>{menu.text}</ContentText>
          <ContentCategory>{renderCategory}</ContentCategory>
        </MenuContentBox>
      </MenuItem>
    );
  });

  return (
    <Container ref={containerEl}>
      <Title>STRONGNESS</Title>
      {renderMenu}
    </Container>
  );
}

export default Strongness;
