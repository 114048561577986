import firebase from "firebase/app";
import "firebase/firestore";

import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";

import CircleBar from "./CircleBar";
import { Section } from "../../styles/ui";
import { PulseLoader } from "react-spinners";

import data from "./questions.json";
//hooks
import useReachedScrollTop from "../../hooks/useReachedScrollTop";

// utils
// import { isSafari } from "../../utils/browser";

const opacityFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export default function ContactUs() {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const containerEl = useRef(null);
  const isReached = useReachedScrollTop(containerEl);

  function handleChange(e, i) {
    const newAnswers = answers.slice();
    newAnswers[i] = e.target.value;
    setAnswers(newAnswers);
  }

  function keyPress(e) {
    if (e.keyCode === 13) {
      handleClick();
    }

    if (e.keyCode === 38 && step !== 0) {
      setStep(step - 1);
    }

    if (e.keyCode === 40) {
      handleClick();
    }
  }

  function validateInput(i) {
    const newSetErrors = errors.slice();
    if (!answers[i] || !(answers.length > 0)) {
      newSetErrors[i] = "계속하려면 입력하세요.";
      setErrors(newSetErrors);
    } else {
      newSetErrors[i] = "";
      setErrors(newSetErrors);
    }
  }

  function handleClick() {
    const { questions } = data;
    validateInput(step);

    if (step < questions.length - 1 && answers[step]) {
      setStep(step + 1);
      inputRefs[step + 1].current.focus();
    } else if (
      step === questions.length - 1 &&
      answers.length === questions.length &&
      answers.every((answer) => answer)
    ) {
      setIsLoading(true);

      const db = firebase.firestore();
      const inquiries = db.collection("inquiries");
      inquiries.add({ ...answers, createdAt: new Date() }).then(() => {
        alert(
          `감사합니다. 문의 주신 내용은 7일 이내에 검토하여 ${
            answers[1]
          } 해당 이메일로 발송하겠습니다.`
        );
        setStep(0);
        setAnswers([]);
        setIsLoading(false);
      });
    }
  }

  return (
    <Container ref={containerEl} isShow={isReached}>
      <ContentBox>
        <QuestionBox>
          {data.questions.map((q, i) => (
            <AbsoluteBox key={i} isSelected={step === i}>
              {errors[i] ? (
                <Error step={step} i={i} isSelected={step === i}>
                  {errors[i]}
                </Error>
              ) : null}
              <Question step={step} i={i} isSelected={step === i}>
                {q.question}
              </Question>
              <InputBox step={step} i={i} isSelected={step === i}>
                <Input
                  ref={inputRefs[i]}
                  type="text"
                  placeholder={q.placeholder}
                  value={answers[i] || ""}
                  onKeyDown={keyPress}
                  onChange={(e) => handleChange(e, i)}
                />
              </InputBox>
            </AbsoluteBox>
          ))}
        </QuestionBox>

        <ButtonBox>
          {isLoading ? (
            <PulseLoader color={"#ccc"} size={15} />
          ) : (
            <Button onClick={handleClick}>
              {step === data.questions.length - 1 ? "제출하기" : "다음"}
            </Button>
          )}
        </ButtonBox>
      </ContentBox>
      <CircleBar
        containerStyle={{ paddingRight: "3rem" }}
        step={step}
        length={data.questions.length}
        onClick={(i) => {
          setStep(i);
          validateInput(i);
        }}
        disables={answers}
        errors={errors}
      />
      <PreventTopBox />
      <PreventBottomBox />
    </Container>
  );
}

const Container = styled(Section)`
  ${(props) => props.theme.mixins.changeOpacity};
  position: relative;
  display: flex;
  padding-top: 20rem;
  padding-bottom: 10rem;

  @media (max-width: 640px) {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
`;
const PreventTopBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 20rem;

  @media (max-width: 640px) {
    height: 10rem;
  }
  z-index: 200;
`;
const PreventBottomBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 10rem;
  @media (max-width: 640px) {
    height: 5rem;
  }
  @media (max-width: 480px) {
    height: 0;
  }
  z-index: 200;
`;

const ContentBox = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const QuestionBox = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  height: 40rem;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ isSelected }) => (isSelected ? 100 : 0)};
`;

const Error = styled.h3`
  position: absolute;
  top: -3rem;
  left: 0;
  ${(props) => props.theme.typo.h3};
  color: orangered;

  padding-left: 3rem;
  padding-right: 3rem;

  transition: all 1s;

  animation: ${opacityFadeIn} 0.3s;

  transform: ${({ step, i }) =>
    `translateY(${step === i ? 0 : step > i ? -20 : 20}rem)`};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};

  @media (max-width: 640px) {
    font-size: 1.6rem;
    transform: ${({ step, i }) =>
      `translateY(${step === i ? 0 : step > i ? -15 : 15}rem)`};
  }
  /* @media (max-width: 480px) {
    font-size: 1.4rem;
    transform: ${({ step, i }) =>
      `translateY(${step === i ? 0 : step > i ? -5 : 5}rem)`};
  } */
`;

const Question = styled.h1`
  ${(props) => props.theme.typo.h1};
  color: '#333';
  font-size: 3rem;
  font-weight: 400;

  padding-left: 3rem;
  padding-right: 3rem;

  transition: all 0.8s;

  transform: ${({ step, i }) =>
    `translateY(${step === i ? 0 : step > i ? -20 : 20}rem)`};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};

  @media (max-width: 640px) {
    font-size: 2.2rem;
    transform: ${({ step, i }) =>
      `translateY(${step === i ? 0 : step > i ? -15 : 15}rem)`};
  }
  @media (max-width: 480px) {
    font-size: 1.6rem;
    /* transform: ${({ step, i }) =>
      `translateY(${step === i ? 0 : step > i ? -5 : 5}rem)`}; */
  }
`;

const InputBox = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;

  transition: all 1s;

  transform: ${({ step, i }) =>
    `translateY(${step === i ? 0 : step > i ? -20 : 20}rem)`};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};

  @media (max-width: 640px) {
    transform: ${({ step, i }) =>
      `translateY(${step === i ? 0 : step > i ? -15 : 15}rem)`};
  }
  /* @media (max-width: 480px) {
    transform: ${({ step, i }) =>
      `translateY(${step === i ? 0 : step > i ? -5 : 5}rem)`};
  } */
`;
const Input = styled.input`
  /* all: unset; */
  font-size: 5rem;
  color: "#333";
  border: none;
  border-bottom: 1px solid #555;
  border-radius: 0;
  -webkit-appearance: none;

  width: 100%;

  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: "#ddd";
  }
  @media (max-width: 640px) {
    font-size: 3.5rem;
  }
  @media (max-width: 480px) {
    font-size: 2.2rem;
  }
`;

const ButtonBox = styled.div`
  position: absolute;
  bottom: 18rem;
  right: 0;

  z-index: 300;

  @media (max-width: 640px) {
    bottom: 18rem;
    right: 2rem;
  }
  @media (max-width: 480px) {
    bottom: 22rem;
    right: 3rem;
  }
`;

const Button = styled.button`
  color: "#333";
  font-size: 2rem;
  font-weight: 400;

  padding: 1rem 3rem;
  border: 1px solid "#333";
  border-radius: 40px;
  background-color: transparent;

  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background-color: #fff;
    color: #000;
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: 640px) {
    font-size: 1.8rem;
  }
  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
`;
