import firebase from "firebase/app";
import "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";

//sections
import Header from "./components/Header";
import Strongness from "./components/Strongness";
import LatestProduct from "./components/LatestProduct";
import PortfolioList from "./components/PortfolioList";
import ContactUs from "./components/ContactUs";
import Technologies from "./components/Technologies";
import FooterNav from "./components/FooterNav";

//utils
import BackColorChanger from "./utils/BackColorChanger";
// import { isSafari } from "./utils/browser";

function App(props) {
  const containerEl = useRef(null);
  const headerEl = useRef(null);
  const strongnessEl = useRef(null);
  const portfolioListEl = useRef(null);
  const contactUsEl = useRef(null);

  const offsetTopsRef = useRef([]);
  const clientHeightsRef = useRef([]);
  const [width, setWidth] = useState(window.innerWidth);

  function updateOffsetTops() {
    offsetTopsRef.current = [];
    containerEl.current.childNodes.forEach((node) => {
      offsetTopsRef.current.push(node.offsetTop);
      clientHeightsRef.current.push(node.clientHeight);
    });
  }

  useEffect(() => {
    const db = firebase.firestore();
    const visitant = db.collection("statistics").doc("website");

    visitant.get().then((doc) => {
      if (doc.exists) {
        db.runTransaction((t) => {
          return t.get(visitant).then((tDoc) => {
            const newTotal = tDoc.data().total + 1;
            t.update(visitant, { total: newTotal });
          });
        }).then(() => {
          console.log(
            "||| LOYCORD |||에 방문해주셔서 감사합니다!! 즐거운 하루 되세요. :)"
          );
        });
      } else {
        visitant.set({ total: 1 });
      }
    });
  }, []);

  useEffect(() => {
    updateOffsetTops();

    const offsetTops = offsetTopsRef.current;
    const clientHeights = clientHeightsRef.current;

    const backColorChanger = new BackColorChanger(
      containerEl.current,
      offsetTops,
      clientHeights
    );
    backColorChanger.updateBackgroundColor(1);

    const handleScroll = () => {
      backColorChanger.changeColorOpacityToSimplex(1);
      // if (!isSafari()) {
      //   backColorChanger.changeColorOpacityToDuplex(4);
      // }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(
    () => {
      const handleResize = () => {
        updateOffsetTops();
        setWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    },
    [width]
  );

  return (
    <div ref={containerEl}>
      <div ref={headerEl}>
        <Header />
      </div>
      <div ref={strongnessEl}>
        <Strongness />
      </div>
      <div ref={portfolioListEl}>
        <LatestProduct />
      </div>
      <div>
        <PortfolioList history={props.history} />
      </div>
      <Technologies />
      <div ref={contactUsEl}>
        <ContactUs />
      </div>
      <FooterNav
        headerEl={headerEl}
        strongnessEl={strongnessEl}
        portfolioListEl={portfolioListEl}
        contactUsEl={contactUsEl}
      />
    </div>
  );
}

export default App;
