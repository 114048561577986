import React from "react";
import styled from "styled-components";

// components
import DetailBox1 from "./DetailBox1";
import DetailBox2 from "./DetailBox2";
import Mockup from "./Mockup";
import AppScreenShot from "./AppScreenShot";

const Container = styled.div`
  width: 100%;
`;

export default function() {
  const data = {
    title: "혼행(MVP)",
    createdBy: "미드나잇플랜",
    createdAt: "2018",
    url: "https://honhang.app",
    client: "미드나잇플랜",
    detail:
      "Keyword. 혼행\n혼행이란‘주위 사람들에게서 벗어나 조용한 시간을 보내기 위해 홀로 여행을 떠나는 것’을 말합니다. 일행의 눈치를 볼 필요도 없고, 내 방식대로 여행할 수 있다는 장점이 있습니다. 그러나 야간 투어를 하거나 혼자는 갈 수 없는 여행지에 가려 할 때는 다른 여행자들과 동행을 하곤 합니다. 혼행 어플리케이션은 동행자를 쉽게 찾을 수 있도록 도와줍니다! 간단한 검색과 프로필 확인으로 내가 원하는 동행자를 찾아서 안전한 여행을 즐기세요.",
    role: ["UI / UX", "Webdesign", "Brand identity", "Development", "Server"],
    displayImg: require("./img/display_honhang.jpg"),
    PM: "Junhyeok Heo/ Seungyeon Kim",
    screenShotImg: require("./img/honhang_all_screen.jpg"),
    screenShotText:
      "혼행의 주요기능은 첫째, 키워드를 통한 간단한 동행 검색이 가능합니다. 간편한 동행 찾기를 위해 하나의 검색 키워드로 글의 제목, 내용, 날짜를 동시에 파악하여 검색됩니다. 둘째, 동행 글 작성자의 프로필을 열람해서 나만의 동행자를 선택 할 수 있습니다. 나와 맞는 여행자인지 판단하는 좋은 도구가 될 것입니다. 셋째, 실시간 채팅이 가능합니다. 채팅을 통해 동행자와 여행에 대해 이야기할 수 있는 기회가 됩니다.",
  };

  return (
    <Container>
      <DetailBox1
        title={data.title}
        client={data.client}
        detail={data.detail}
        role={data.role}
        url={data.url}
      />
      <Mockup
        mockup={data.mockup}
        img={data.displayImg}
        containerStyle={{ backgroundColor: "#d7d7d7" }}
      />
      <AppScreenShot
        screenShotImg={data.screenShotImg}
        screenShotText={data.screenShotText}
      />
      <DetailBox2 url={data.url} PM={data.PM} createdAt={data.createdAt} />
    </Container>
  );
}
