import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

//svgs
import { ReactComponent as Logo } from '../common/svg/loycord_logo.svg';

const Container = styled.footer`
  width: 100%;
  background-color: ${props => props.theme.colors.GREY_LIGHT_1};
  padding-top: 5rem;
  padding-bottom: 20rem;

  @media (max-width: 800px) {
    padding-bottom: 5rem;
  }
`;
const CompanyBack = styled.div`
  max-width: 120rem;
  margin: 0 auto;
  width: 100%;
`;
const Company = styled.div`
  display: flex;
  padding-left: 3rem;
  padding-right: 3rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
const Row1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-bottom: 5rem;
  }
`;
const Row2 = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  text-align: center;

  flex-direction: column;
`;
const Row3 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const StyledLogo = styled(Logo)`
  width: 5rem;
`;

const Text = styled.span`
  font-size: 1.2rem;
  margin-top: 5px;
`;
const Copyright = styled.span`
  ${props => props.theme.typo.p4};
  color: ${props => props.theme.colors.GREY_LIGHT_4};
`;

const LinkText = styled(Link)`
  text-decoration: none;
  color: lightblue;
  border-bottom: 1px solid lightblue;
  font-size: 1.2rem;

  cursor: pointer;

  &:hover {
    color: blue;
    border-bottom: 1px solid blue;
  }
  &:active {
    color: coral;
    border-bottom: 1px solid coral;
  }
`;

const NavContainer = styled.nav`
  margin-top: 1rem;
`;

export default function() {
  return (
    <Container>
      <CompanyBack>
        <Company>
          <Row1>
            <StyledLogo style={{ marginBottom: '1rem' }} />
            <Text>loycord.ceo@gmail.com</Text>
            <Text>사업자등록번호: 630-33-00304</Text>
            <NavContainer>
              <LinkText to="/privacy">개인정보처리방침</LinkText>
            </NavContainer>
          </Row1>
          <Row2>
            <Copyright>
              Copyright ⓒ 2017-2018 Loycord All right reserved.
            </Copyright>
          </Row2>
          <Row3 />
        </Company>
      </CompanyBack>
    </Container>
  );
}
