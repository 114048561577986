import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// components
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import Menu from "./components/Menu";

//pages
import App from "./App";
import Honhang from "./components/Portfolio/Honhang";
import Sixtysixdays from "./components/Portfolio/66days";
import Bapjeongneo from "./components/Portfolio/Bapjeongneo";
import Privacy from "./components/Privacy";

//hoc
import withStyles from "./styles";
import withLoading from "./hoc/Loading";

// context
import { Context as MenuContext } from "./context/menu";

function Root() {
  const menu = React.useContext(MenuContext);

  return (
    <BrowserRouter>
      <>
        <AppBar />
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/portfolio/honhang" component={Honhang} />
          <Route path="/portfolio/66days" component={Sixtysixdays} />
          <Route path="/portfolio/bapjeongneo" component={Bapjeongneo} />
          <Route path="/privacy" component={Privacy} />
          <Route render={() => "404 잘못된 접근입니다."} />
        </Switch>
        <Footer />
        <Menu isHide={menu.state.isHide} />
      </>
    </BrowserRouter>
  );
}

const withes = [withStyles, withLoading];
export default withes.reduce((Component, hoc) => hoc(Component), Root);
