import { useState, useEffect } from 'react';

export default function useReachedScrollTop(ref) {
  const [isReached, setIsReached] = useState(false);

  function handleScroll() {
    const windowBottomY = window.pageYOffset + window.innerHeight;
    const targetMiddleY = ref.current.offsetTop + ref.current.clientHeight / 2;

    if (windowBottomY > targetMiddleY) {
      setIsReached(true);
      window.removeEventListener('scroll', handleScroll);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return isReached;
}
