import React, { createContext, useReducer } from 'react';

const Context = createContext();

const initialState = {
  isHide: true
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'off':
      return { isHide: true };
    case 'on':
      return { isHide: false };
    default:
      return state;
  }
};

function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
}

const Consumer = Context.Consumer;

export { Context, Provider, Consumer };
