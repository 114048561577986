import React from 'react';
import styled from 'styled-components';

//components
import DetailBox1 from './DetailBox1';
import DetailBox2 from './DetailBox2';

const Container = styled.div`
  width: 100%;
`;

export default function() {
  const data = {
    title: '66 Days',
    createdBy: 'Loycord',
    createdAt: '2018.10',
    url: 'https://days-8e727.firebaseapp.com',
    client: 'Loycord',
    detail:
      'UCL. University College London 에서 습관에 관한 연구를 진행 했습니다. 연구에 참여한 사람들이 습관을 형성하는데 걸린 평균 시간은 66일이라고 합니다. 습관을 형성하기 위해서는 많은 노력을 들여야 하는 것은 아닙니다. 왜냐하면 그 행동을 충분히 오래동안 반복하기만 하면 습관이 되어 자동적으로 하게 될 것이기 때문이죠.\n습관 행동을 하루에 1개 이상만 체크하면 연속 일수가 계속 진행됩니다. 66일 진행하여 지금 바로 목표를 위한 행동들을 자신의 습관으로 만들어보세요.',
    role: [
      'UI / UX',
      'Plan',
      'Logo',
      'Brand identity',
      'React Native',
      'Firebase'
    ],
    displayImg: require('./img/display_66days.jpg'),
    PM: 'Seungyeon Kim'
  };

  return (
    <Container>
      <DetailBox1
        title={data.title}
        client={data.client}
        detail={data.detail}
        url={data.url}
        role={data.role}
      />
      <Mockup>
        <Img src={require('./img/66days_display.png')} />
      </Mockup>
      <Img
        src={require('./img/66days_all_screen.jpg')}
        alt="66days_all_screen"
      />
      <DetailBox2 url={data.url} PM={data.PM} createdAt={data.createdAt} />
    </Container>
  );
}

const Img = styled.img`
  width: 100%;
`;
const Mockup = styled.div`
  background-color: #80a1d0;
`;