import React from "react";
import styled from "styled-components";

// utils
// import { isSafari } from "../../utils/browser";

export default function CircleBar({
  containerStyle,
  length,
  step,
  onClick,
  disables = [],
  errors = [],
  ...props
}) {
  function handleClick(i) {
    if (disables[i - 1] || disables[i] || errors[i]) {
      onClick(i);
    }
  }

  // const safari = isSafari();
  const selectedColor = "#333";
  const disabledColor = "#999";
  const baseColor = "#ccc";

  return (
    <CircleBox style={containerStyle}>
      {Array(length)
        .fill(null)
        .map((_, i) => {
          const isError = errors[i];
          const isSelected = step === i;
          const isDisable = Boolean(disables[i - 1] || disables[i]);

          let color = baseColor;
          if (isSelected) {
            color = selectedColor;
          } else if (isError) {
            color = "red";
          } else if (isDisable) {
            color = disabledColor;
          }

          return (
            <Circle
              key={i}
              color={color}
              isSelected={isSelected}
              onClick={() => handleClick(i)}
            />
          );
        })}
    </CircleBox>
  );
}

const CircleBox = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  &:last-child {
    margin-bottom: 1.2rem;
  }
`;
const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-top: 1.2rem;

  background-color: ${(props) => props.color};
  transform: ${({ isSelected }) => `rotateY(${isSelected ? "0" : "180deg"})`};
  transition: all 0.8s;

  cursor: pointer;
`;
