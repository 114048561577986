import React from 'react';
import styled from 'styled-components';
// components
import Script from './Script';
import GravityDotCanvas from './GravityDotCanvas';

export default function Header() {
  return (
    <Container>
      <Script />
      <GravityDotCanvas />
    </Container>
  );
}

const Container = styled.header`
  width: 100%;
  margin: 0 auto;
  max-width: 120rem;
  position: relative;
  display: grid;
  height: 80rem;
  grid-template-columns: 40rem 1fr;
  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;
