import React from 'react';

import styled from 'styled-components';
import { ReactComponent as IconScroll } from '../../common/svg/icon_scroll.svg';

const Display = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90rem;
  background-color: ${props => props.displayBackColor};
  position: relative;

  @media (max-width: 1000px) {
    display: none;
  }
`;
const IconBox = styled.div`
  position: absolute;
  top: 45%;
  left: 15%;
  transform: translate(-50%, -50%);
  @media (max-width: 1350px) {
    left: 5%;
  }
`;
const StyledIconScroll = styled(IconScroll)`
  width: 6rem;
`;
const Mockup = styled.img`
  @media (max-width: 1000px) {
    transform: scale(0.75);
  }
`;
const DisplayImgBox = styled.div`
  position: absolute;
  top: 42%;
  left: 50%;
  width: 100%;
  max-width: 80.4rem;
  transform: translate(-50%, -50%);
  height: 45.8rem;
  overflow-x: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px !important;
  }
`;
const DisplayImg = styled.img`
  max-width: 100%;
`;

export default function({ img, containerStyle, ...props }) {
  return (
    <Display style={containerStyle} >
      <IconBox>
        <StyledIconScroll />
      </IconBox>
      <Mockup src={require('./img/imac.png')} />
      <DisplayImgBox>
        <DisplayImg src={img} />
      </DisplayImgBox>
    </Display>
  );
}
