/**
 *
 * @param {ref} ref target ref
 * @param {number[]} offsetTops offsetTops of target ref's child components
 * @param {number[]} clientHeights clientHeights of target ref's child components
 */
export default function BackColorChanger(ref, offsetTops, clientHeights) {
  this.ref = ref;
  this.offsetTops = offsetTops;
  this.clientHeights = clientHeights;
}

BackColorChanger.prototype.getWindowMiddleY = function() {
  return window.innerHeight / 2 + window.pageYOffset;
};

BackColorChanger.prototype.getMiddleY = function(index) {
  return this.offsetTops[index] + this.clientHeights[index] / 2;
};

BackColorChanger.prototype.gapDiff = function(index) {
  const aMiddleY = this.getMiddleY(index);

  const a = this.getWindowMiddleY() - aMiddleY;
  const b = this.getMiddleY(index + 1) - aMiddleY;

  return a / b;
};

BackColorChanger.prototype.updateBackgroundColor = function(opacity) {
  this.ref.style.backgroundColor = `rgba(0, 0, 0, ${opacity})`;
};

BackColorChanger.prototype.changeColorOpacityToSimplex = function(index) {
  const opacity =
    (this.getMiddleY(index) - this.getWindowMiddleY()) /
    (this.getMiddleY(index) - window.innerHeight / 2);

  this.updateBackgroundColor(opacity);
};

BackColorChanger.prototype.changeColorOpacityToDuplex = function(index) {
  const startToMiddle = this.gapDiff(index);
  const middleToEnd = this.gapDiff(index + 1);

  if (startToMiddle >= 0 && startToMiddle < 1) {
    this.updateBackgroundColor(startToMiddle);
  } else if (middleToEnd >= 0 && middleToEnd < 1) {
    this.updateBackgroundColor(1 - middleToEnd);
  }
};
