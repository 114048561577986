import { css } from 'styled-components';

const absoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const changeOpacity = css`
  opacity: ${props => (props.isShow ? '1' : '0')};
  transition: opacity 1.5s;
`;

const changeTranslateY = css`
  opacity: ${props => (props.isShow ? '1' : '0')};
  transform: ${props => (props.isShow ? 'translateY(0)' : 'translateY(100px)')};
  transition: all 1s;
`;

export default {
  absoluteCenter,
  changeOpacity,
  changeTranslateY
};
