import React, { useRef } from 'react';
import styled from 'styled-components';

//svgs
import { ReactComponent as Arrow } from '../common/svg/arrow_right.svg';
import { ReactComponent as Git } from '../common/svg/icon_git.svg';
import { ReactComponent as Blog } from '../common/svg/icon_blog.svg';
//ui
import { Section } from '../styles/ui';
//hooks
import useReachedScrollTop from '../hooks/useReachedScrollTop';

const Container = styled(Section)`
  ${props => props.theme.mixins.changeOpacity};
`;
const SubTitle = styled.p`
  ${props => props.theme.typo.p4};
  color: ${props => props.theme.colors.GREY_LIGHT_4};
  padding-top: 15rem;
  text-align: center;

  @media (max-width: 1000px) {
    display: none;
  }
  @media (max-width: 600px) {
    padding-top: 0;
  }
`;
const Nav = styled.div`
  padding-bottom: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const Title = styled.div`
  ${props => props.theme.typo.h1};
  color: ${props => props.theme.colors.GREY_DARK_2};
  display: flex;
  font-weight: 400 !important;
  cursor: pointer;
  & span {
    padding-left: 1rem;
    margin-top: 3px;
  }
  &:hover span {
    transition: all 0.3s ease-in-out;
    transform: translateX(1rem);
  }

  @media (max-width: 1150px) {
    ${props => props.theme.typo.h2};
  }
  @media (max-width: 600px) {
    & span {
      display: none;
    }
    padding: 0 3rem;
  }
  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;
const Row1 = styled.div`
  flex: 1;
  padding-left: 3rem;

  @media (max-width: 1000px) {
    padding-left: 0;
  }
`;
const Row2 = styled.div`
  display: flex;
  ${props => props.theme.typo.p4};
  color: ${props => props.theme.colors.GREY_LIGHT_4};
  flex: 2.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 1000px) {
    padding: 5rem 0;
  }
`;
const Row3 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 3rem;

  @media (max-width: 1000px) {
    padding-right: 0;
    align-items: center;
  }
`;
const Link = styled.a`
  text-decoration: none;
`;
const StyledGit = styled(Git)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;
const StyledBlog = styled(Blog)`
  width: 3rem;
  height: 3rem;
`;
const FooterNavBox = styled.div`
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  &::after {
    content: '';
    display: flex;
    width: 0;
    height: 1px;
    background-color: ${props => props.theme.colors.GREY_DARK_2};
    transition: width 0.3s;
  }
  &:hover::after {
    width: 100%;
  }
  &:hover li {
    color: ${props => props.theme.colors.GREY_DARK_2};
  }
`;
const FooterNav = styled.li`
  ${props => props.theme.typo.p4};
  color: ${props => props.theme.colors.GREY_LIGHT_4};
  list-style: none;
  padding-bottom: 0.2rem;
`;

export default function({
  headerEl,
  strongnessEl,
  portfolioListEl,
  contactUsEl
}) {
  const containerEl = useRef(null);
  const isReached = useReachedScrollTop(containerEl);
  const handleClick = target => {
    target.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Container ref={containerEl} isShow={isReached}>
      <SubTitle>REACH OUT TO US.</SubTitle>
      <Nav>
        <Row1>
          <Link href="https://github.com/loycord">
            <StyledGit />
          </Link>
          <Link href="https://huurray.github.io/">
            <StyledBlog />
          </Link>
        </Row1>
        <Row2 onClick={() => handleClick(contactUsEl)}>
          <Title>
            당신의 컨셉을 기다리고 있습니다.
            <span>
              <Arrow />
            </span>
          </Title>
        </Row2>
        <Row3>
          <FooterNavBox onClick={() => handleClick(headerEl)}>
            <FooterNav>About</FooterNav>
          </FooterNavBox>
          <FooterNavBox onClick={() => handleClick(strongnessEl)}>
            <FooterNav>Strongness</FooterNav>
          </FooterNavBox>
          <FooterNavBox onClick={() => handleClick(portfolioListEl)}>
            <FooterNav>Portfolio</FooterNav>
          </FooterNavBox>
          <FooterNavBox onClick={() => handleClick(contactUsEl)}>
            <FooterNav>Contact</FooterNav>
          </FooterNavBox>
        </Row3>
      </Nav>
    </Container>
  );
}
