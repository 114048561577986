import React from 'react';
import styled from 'styled-components';
import { Context as MenuContext } from '../context/menu';

export default function Menu({ isHide }) {
  const menu = React.useContext(MenuContext);

  return (
    <Container isHide={isHide}>
      <Text>asdkbaskjdbas asdkbaskjdbasask kasbdkj</Text>
      <Close onClick={() => menu.dispatch({ type: 'off' })}>close</Close>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  display: flex;
  flex: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: center;
  align-items: center;

  transition: all 0.3s;

  opacity: ${props => (props.isHide ? 0 : 1)};
  z-index: ${props => (props.isHide ? -10 : 99999)};

  background-color: #000;
`;

const Text = styled.p`
  color: #fff;
`;

const Close = styled.span`
  position: fixed;
  top: 3.5rem;
  right: 3.3rem;
  ${({ theme }) => theme.typo.p1};
  color: #fff;

  padding: 1.5rem;
  cursor: pointer;
  z-index: 9999;

  transition: all 0.3s;

  &:hover {
    color: #434343;
  }
`;
