import React, { createContext, useReducer } from 'react';

const Context = createContext();

const initialState = {
  state: 'settled'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'start':
      return { state: 'pending' };
    case 'end':
      return { state: action.state || 'settled' };
    default:
      return state;
  }
};

function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>{props.children}</Context.Provider>
  );
}

const Consumer = Context.Consumer;

export { Context, Provider, Consumer };
