const MAX_LEFT_OFFSET = 430;
const LEFT_OFFSET_PERCENT = 0.42;

export default function GravityCircle(canvas, x, y, dx, dy, radius) {
  this.prevLeft =
    window.innerWidth * LEFT_OFFSET_PERCENT > MAX_LEFT_OFFSET
      ? window.innerWidth * LEFT_OFFSET_PERCENT
      : MAX_LEFT_OFFSET;

  this.c = canvas;
  this.x = x + this.prevLeft;
  this.y = y;
  // this.v = v;
  this.initX = x + this.prevLeft;
  this.initY = y;
  this.dx = dx;
  this.dy = dy;
  this.initDX = dx;
  this.initDY = dy;
  // this.initV = this.v;
  this.radius = radius;
  this.gravity = 0.95;

  this.initRadius = radius;
}

GravityCircle.prototype.draw = function() {
  this.c.beginPath();
  this.c.arc(
    this.x + this.dx,
    this.y + this.dy,
    this.radius,
    0,
    Math.PI * 2,
    false
  );
  this.c.closePath();
};

GravityCircle.prototype.update = function(width, mouse, gap = 60) {
  this.draw();

  if (
    Math.abs(this.initX + this.radius - mouse.x) < gap - gap / 5 &&
    Math.abs(this.initY + this.radius - mouse.y - window.pageYOffset) <
      gap - gap / 5
  ) {
    if (Math.abs(this.x - mouse.x) > this.radius) {
      // if (this.dx > 0.5) {
      //   this.dx *= this.gravity;
      // }
      if (this.x > mouse.x) {
        this.x -= this.dx;
      } else {
        this.x += this.dx;
      }
    } else {
      this.x = mouse.x;
    }

    if (Math.abs(this.y - mouse.y - window.pageYOffset) > this.radius) {
      // if (this.dy > 0.5) {
      //   this.dy *= this.gravity;
      // }
      if (this.y > mouse.y + window.pageYOffset) {
        this.y -= this.dy;
      } else {
        this.y += this.dy;
      }
    } else {
      this.y = mouse.y + window.pageYOffset;
    }
    this.c.fillStyle = '#ddd';
    this.c.fill();
  } else {
    if (
      this.prevLeft !== window.innerWidth * LEFT_OFFSET_PERCENT &&
      window.innerWidth * LEFT_OFFSET_PERCENT > MAX_LEFT_OFFSET
    ) {
      this.initX =
        this.initX - this.prevLeft + window.innerWidth * LEFT_OFFSET_PERCENT;
      this.x = this.initX;
      this.prevLeft = window.innerWidth * LEFT_OFFSET_PERCENT;
    }

    if (
      Math.abs(this.x - this.initX) !== 0 &&
      Math.abs(this.x - this.initX) > this.radius
    ) {
      // if (this.dx > 0.5) {
      //   this.dx *= this.gravity;
      // }
      if (this.x > this.initX) {
        this.x -= this.dx;
      } else {
        this.x += this.dx;
      }
    } else {
      this.dx = this.initDX;
      this.x = this.initX;
    }

    if (
      Math.abs(this.y - this.initY) !== 0 &&
      Math.abs(this.y - this.initY) > this.radius
    ) {
      // if (this.dy > 0.5) {
      //   this.dy *= this.gravity;
      // }
      if (this.y > this.initY) {
        this.y -= this.dy;
      } else {
        this.y += this.dy;
      }
    } else {
      this.dy = this.initDY;
      this.y = this.initY;
    }
    this.c.fillStyle = '#444';
    this.c.fill();
  }
};
