import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// components
import GravityCircle from '../canvas/GravityCircle';

function useWindowMouseMove() {
  const [mouse, setMouse] = useState({ x: undefined, y: undefined });

  useEffect(() => {
    const handleMouseMove = event => {
      setMouse({ x: event.x, y: event.y });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mouseover', handleMouseMove);
    };
  }, []);

  return mouse;
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
}

export default function GravityDotCanvas(props) {
  const canvasEl = React.useRef(null);
  const mouse = useWindowMouseMove();
  const width = useWindowWidth();
  const mouseRef = React.useRef(mouse);

  useEffect(() => {
    const canvas = canvasEl.current;
    canvas.width = width - 8;
  }, [width]);

  useEffect(() => {
    const canvas = canvasEl.current;
    const c = canvas.getContext('2d');
    canvas.width = width - 8;
    canvas.height = 800;

    const circles = [];
    const col = 12;
    const row = 11;
    const gap = 60;
    const defaultX = 0;
    const defaultY = 130;
    let x = defaultX;
    let y = defaultY;

    for (let i = 0; i < col * row; i++) {
      circles.push(new GravityCircle(c, x, y, 2.5, 2.5, 3));

      x += gap;
      if ((i + 1) % col === 0) {
        x = defaultX;
        y += gap;
      }
    }

    function animate() {
      requestAnimationFrame(animate);
      c.clearRect(0, 0, canvas.width, canvas.height);
      circles.forEach(circle => {
        circle.update(width, mouseRef.current, gap);
      });
    };

    animate();
  }, []);

  mouseRef.current = mouse;

  return <Canvas ref={canvasEl} />;
}

const Canvas = styled.canvas`
  position: absolute;
  display: flex;
  flex: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
