import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    font-size: 62.5%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  /* body > * {
    all: unset;
  } */

  body {
    line-height: normal;
    box-sizing: border-box;
    font-family: 'Noto Sans KR', sans-serif;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(51,51,51, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background: #777; 
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #333; 
  }

`;
