import React from 'react';

import styled from 'styled-components';

//ui
import { Section } from '../../styles/ui';

const Container = styled.div`
  overflow: hidden;
`;
const SectionBox = styled(Section)`
  display: flex;
  flex-direction: column;
  padding-top: 15rem;
  padding-bottom: 5rem;
  @media (max-width: 1000px) {
    padding-top: 5rem;
  }
`;
const Box = styled.div`
  padding: 0 3rem;
  position: relative;
`;
const Text = styled.p`
  ${props => props.theme.typo.h5};
  font-weight: 400;
  color: ${props => props.theme.colors.GREY_DARK_3};
  max-width: 40rem;
  padding-right: 3rem;
  line-height: 2.2;
  position: absolute;
  top: 0;
  left: 3rem;
`;
const BackImg = styled.img`
  margin-top: 7rem;
`;

export default function({ screenShotImg, screenShotText }) {
  return (
    <Container>
      <SectionBox>
        <Box>
          <Text>{screenShotText}</Text>
          <BackImg src={screenShotImg} />
        </Box>
      </SectionBox>
    </Container>
  );
}
