import { css } from 'styled-components';

const h1 = css`
  font-weight: 700;
  font-size: 3rem;
`;
const h2 = css`
  font-weight: 700;
  font-size: 2.5rem;
`;
const h3 = css`
  font-weight: 700;
  font-size: 2rem;
`;
const h4 = css`
  font-weight: 700;
  font-size: 1.8rem;
`;
const h5 = css`
  font-weight: 700;
  font-size: 1.6rem;
`;
const h6 = css`
  font-weight: 700;
  font-size: 1.5rem;
`;
const h7 = css`
  font-weight: 700;
  font-size: 1.4rem;
`;
const h8 = css`
  font-weight: 700;
  font-size: 1.3rem;
`;

const p1 = css`
  font-weight: 400;
  font-size: 1.6rem;
`;
const p2 = css`
  font-weight: 400;
  font-size: 1.5rem;
`;
const p3 = css`
  font-weight: 400;
  font-size: 1.4rem;
`;
const p4 = css`
  font-weight: 400;
  font-size: 1.3rem;
`;
const p5 = css`
  font-weight: 400;
  font-size: 1.2rem;
`;

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8,
  p1,
  p2,
  p3,
  p4,
  p5
};
