import React from 'react';
import styled from 'styled-components';
import logo from '../common/svg/loycord_logo.svg';
import { fadeIn } from '../styles/keyframes';
import { Link } from 'react-router-dom';

// import { Context as MenuContext } from '../context/menu';

export default () => {
  // const menu = React.useContext(MenuContext);

  return (
    <div>
      <Link to="/">
        <Logo src={logo} alt="logo" />
      </Link>
      {/* <Menu onClick={() => menu.dispatch({ type: 'on' })}>menu</Menu> */}
    </div>
  );
};

const Logo = styled.img`
  position: absolute;
  top: 5rem;
  left: 6rem;
  height: 3rem;

  animation: ${fadeIn} 1s;

  z-index: 9999;

  @media (max-width: 560px) {
    top: 4rem;
    left: 3rem;
    height: 2.2rem;
  }
  @media (max-width: 480px) {
    height: 1.8rem;
  }
`;
// const Menu = styled.span`
//   position: fixed;
//   top: 3.5rem;
//   right: 3rem;
//   ${({ theme }) => theme.typo.p1};
//   color: #353535;

//   animation: ${fadeIn} 1s;

//   padding: 1.5rem;
//   cursor: pointer;
//   z-index: 9999;

//   transition: all 0.3s;

//   &:hover {
//     color: #434343;
//   }

//   @media (max-width: 560px) {
//     top: 2.5rem;
//     right: 1rem;
//     font-size: 1.6rem;
//   }
//   @media (max-width: 480px) {
//     font-size: 1.4rem;
//   }
// `;
