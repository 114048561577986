import React, { useState, useRef } from "react";
import styled from "styled-components";

//slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../common/slick.css";
//ui
import { Section } from "../styles/ui";
//hooks
import useReachedScrollTop from "../hooks/useReachedScrollTop";

const data = [
  {
    title: "혼행(MVP)",
    route: "honhang",
    createdBy: "미드나잇플랜",
    createdAt: "2018",
    thumbImg: require(`../common/img/thumb_honhang.png`),
  },
  {
    title: "66days",
    route: "66days",
    createdBy: "Loycord",
    createdAt: "2018",
    thumbImg:
      "https://firebasestorage.googleapis.com/v0/b/days-8e727.appspot.com/o/hosting%2Fscreen%2F66days_0.png?alt=media&token=f2f9dcda-ffb7-456e-8e49-20f0bddca4d3",
  },
  {
    title: "밥정너",
    route: "bapjeongneo",
    createdBy: "세얼간이",
    createdAt: "2017",
    thumbImg: require(`../common/img/thumb_bapjeongneo.png`),
  },
];

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  padding: 5rem 0 5rem 0;
`;
const Title = styled.div`
  ${(props) => props.theme.typo.p4};
  margin: 3rem 0 8rem 0;
  color: ${(props) => props.theme.colors.GREY_LIGHT_4};
  padding: 0 3rem;
`;
const List = styled.div`
  height: 40rem;
  cursor: pointer;
  &:not(:last-child) {
    padding: 1rem;
  }
  ${(props) => props.theme.mixins.changeOpacity};
`;
const Img = styled.img`
  height: 35rem;
  object-fit: cover;
  object-position: center;
  padding-bottom: 2rem;

  transition: all 0.5s ease-in-out;
  transform: scale(0.9);

  &:hover {
    transform: scale(1);
  }
`;
const ProjectTitle = styled.h2`
  ${(props) => props.theme.typo.h2};
  font-weight: 500;
  font-style: normal;
  padding-bottom: 2rem;
`;
const CreatedBy = styled.p`
  ${(props) => props.theme.typo.p4};
  line-height: 1.8;
  color: ${(props) => props.theme.colors.GREY_DARK_1};
`;

export default function(props) {
  const [isDrag, setIsDrag] = useState(false);
  const containerEl = useRef(null);
  const isReachedContainerEl = useReachedScrollTop(containerEl);

  const slickSettings = {
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          autoplay: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          arrows: false,
          autoplay: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  };

  const handleMouseDown = () => {
    setIsDrag(false);
  };
  const handleMouseMove = () => {
    setIsDrag(true);
  };
  const handleMouseUp = (title) => {
    if (!isDrag) {
      props.history.push(`/portfolio/${title}`);
    }
  };

  React.useEffect(() => {
    return () => window.scrollTo(0, 0);
  }, []);

  return (
    <Container ref={containerEl}>
      <Title>PORTFOLIO</Title>
      <Slider {...slickSettings}>
        {data.map((portfolio) => (
          <ListItem
            key={portfolio.title}
            portfolio={portfolio}
            isReachedContainerEl={isReachedContainerEl}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={() => handleMouseUp(portfolio.route)}
          />
        ))}
      </Slider>
    </Container>
  );
}

function ListItem({ portfolio, ...props }) {
  const { thumbImg, title, createdBy, createdAt } = portfolio;

  return (
    <List {...props} isShow={props.isReachedContainerEl}>
      <Img src={thumbImg} />
      <ProjectTitle>{title}</ProjectTitle>
      <CreatedBy>
        {createdBy}
        <br />
        {createdAt}
      </CreatedBy>
    </List>
  );
}
