import React, { useRef } from "react";
import styled from "styled-components";
//ui
import { Section } from "../styles/ui";
//hooks
import useReachedScrollTop from "../hooks/useReachedScrollTop";

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
`;
const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
  @media (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 5rem;
  }
`;
const Title = styled.div`
  ${(props) => props.theme.typo.p4};
  margin-bottom: 5rem;
  padding: 0 3rem;
  color: ${(props) => props.theme.colors.GREY_LIGHT_4};
`;
const Text = styled.p`
  ${(props) => props.theme.mixins.changeTranslateY};
  ${(props) => props.theme.typo.p2};
  line-height: 2.5;
  max-width: 37rem;
  padding: 0 2rem 5rem 0;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;
const LinkBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const Link = styled.a`
  padding: 1rem 15rem 1rem 1rem;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 2px solid ${(props) => props.theme.colors.GREY_LIGHT_2};
  }
  &:hover {
    color: ${(props) => props.theme.colors.BLACK};
  }
  &:hover::after {
    content: "→";
    position: absolute;
    top: 30%;
    right: 0.5rem;
  }

  @media (max-width: 800px) {
    padding-right: 10rem;
  }
  @media (max-width: 600px) {
    padding: 1rem 0;
  }
`;
const LinkText = styled.p`
  ${(props) => props.theme.mixins.changeTranslateY};
  ${(props) => props.theme.typo.p2};
  color: ${(props) => props.theme.colors.GREY_DARK_1};
`;

function LatestProduct() {
  const TextEl = useRef(null);
  const LinkTextEl_1 = useRef(null);
  const LinkTextEl_2 = useRef(null);
  const isReachedTextEl = useReachedScrollTop(TextEl);
  const isReachedLinkTextEl_1 = useReachedScrollTop(LinkTextEl_1);
  const isReachedLinkTextEl_2 = useReachedScrollTop(LinkTextEl_2);

  return (
    <Container>
      <Title>LATEST PRODUCT</Title>
      <ContentBox>
        <Text ref={TextEl} isShow={isReachedTextEl}>
          로이코드는 고객의 목표를 달성하는 것만 아니라 우리의 목표도
          설정합니다. 우리의 경험을 최대한 활용하여 유용한 제품을 만드는 데
          사용합니다.
        </Text>
        <LinkBox>
          <Link ref={LinkTextEl_1} href="https://honhang.app">
            <LinkText isShow={isReachedLinkTextEl_1}>honhang.app</LinkText>
          </Link>
          <Link ref={LinkTextEl_2} href="https://days-8e727.firebaseapp.com/">
            <LinkText isShow={isReachedLinkTextEl_2}>66days.com</LinkText>
          </Link>
        </LinkBox>
      </ContentBox>
    </Container>
  );
}

export default LatestProduct;
