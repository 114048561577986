import React from 'react';
// style
import styled from 'styled-components';

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  `,
  Box: styled.div`
    display: flex;
    padding: 2.5rem 0;
    width: 90%;
    max-width: 72rem;
    flex-direction: column;
    align-items: flex-start;
  `,
  Title: styled.h1`
    font-size: 2.8rem;
    font-weight: 700;
    margin: 1.4rem 0;
  `,
  SubTitle: styled.h2`
    font-size: 2rem;
    font-weight: 600;
    margin: 1rem 0;
  `,
  Text: styled.p`
    font-size: 1.6rem;
    line-height: 1.5;
    color: #434a54;
    margin: 0.5rem 0;
  `,
  UList: styled.ul`
    list-style-type: ${props => (props.type ? props.type : 'disc')};
  `,
  OList: styled.ol`
    list-style-type: decimal;
  `,
  ListItem: styled.li`
    line-height: 1.5;
    margin: 1.5rem 0 1.5rem 2rem;
    font-size: 1.6rem;
    color: #434a54;
  `
};

export default function PrivacyPage() {
  return (
    <S.Container>
      <S.Box
        style={{
          borderBottom: '1px solid #e6e9ed',
          padding: '5rem 0',
          marginTop: '10rem'
        }}
      >
        <S.Title>서비스 개인정보처리방침</S.Title>
        <S.Text style={{ color: '#656d78', fontWeight: 300 }}>
          로이코드는 이용자들의 개인정보를 소중히 다루고 있습니다.
        </S.Text>
        <S.Text>개정 2018. 4. 17</S.Text>
      </S.Box>
      <S.Box>
        <S.SubTitle>1. 개인정보 처리방침의 기본방향</S.SubTitle>
        <S.Text>
          Loycord 서비스를 제공하는 로이코드(이하 회사)는 서비스 이용을 위해
          회원이 제공하신 개인 정보를 매우 중요하게 여기고, 개인정보 처리방침을
          수립하여 이를 엄격히 준수하고 있습니다. 회사의 개인정보 처리방침은
          관련 법령 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있으며,
          개인정보가 유출되어 피해를 입지 않도록 개인정보 보호에 최선을 다할
          것입니다. 회원 여러분은 언제든지 이 처리방침을 열람하여 확인하실 수
          있으며, 개인정보 처리방침은 법령 및 정부지침의 변경과 보다 나은 서비스
          제공을 위해서 그 내용이 변경될 수 있습니다.
        </S.Text>
      </S.Box>
      <S.Box>
        <S.SubTitle>2. 목차</S.SubTitle>
        <S.Text>
          회사의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.
        </S.Text>
        <S.UList>
          <S.ListItem>개인정보의 수집목적 및 이용목적</S.ListItem>
          <S.ListItem>개인정보의 수집범위 및 수집방법</S.ListItem>
          <S.ListItem>개인정보의 이용 및 제공</S.ListItem>
          <S.ListItem>개인정보에 대한 회원의 권리 및 행사방법</S.ListItem>
          <S.ListItem>개인정보의 보유 및 이용기간</S.ListItem>
          <S.ListItem>개인정보 보호에 대한 기술적 관리적 대책</S.ListItem>
          <S.ListItem>쿠키(cookie)의 운영에 관한 사항</S.ListItem>
          <S.ListItem>어린이 정보 보호에 관한 사항</S.ListItem>
          <S.ListItem>광고성 정보전송</S.ListItem>
          <S.ListItem>개인정보 관리 의무 및 책임자 공시</S.ListItem>
        </S.UList>
      </S.Box>
      <S.Box>
        <S.SubTitle>3. 개인정보의 수집목적 및 이용목적</S.SubTitle>
        <S.Text>
          회사가 회원으로부터 개인정보를 수집하는 이유와 개인정보의 이용목적은
          회사가 운영하는 사이트(https://loycord.com)를 통해 회원에게 최적의
          서비스를 제공하기 위한 목적으로, 이에 필요한 개인정보를 수집하고
          있습니다. 회원이 제공한 개인정보는 회원에게 유용한 정보 제공이나
          회원이 요청한 각종 의견에 대한 답변 제공 등에 사용되고 있습니다.
        </S.Text>
      </S.Box>
      <S.Box>
        <S.SubTitle>4. 개인정보의 수집범위 및 수집방법</S.SubTitle>
        <S.OList>
          <S.ListItem>
            수집범위
            <S.UList>
              <S.ListItem>
                회사는 적법하고 공정한 절차와 이용자의 동의를 받은 개인정보를
                수집합니다. 이를 위해 개인정보 처리방침 또는 이용약관의 내용에
                대하여 「동의」버튼을 클릭할 수 있는 절차를 마련하여,
                「동의」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로
                봅니다.
              </S.ListItem>
              <S.ListItem>
                회사가 수집하는 개인정보 항목 : 이름, 이메일주소, 비밀번호,
                생년월일
              </S.ListItem>
              <S.ListItem>
                홈페이지 회원 가입 시 주민등록번호를 요구하지 않습니다.
              </S.ListItem>
              <S.ListItem>
                회사는 회원의 기본적인 인권 침해 우려가 있는 민감한 개인정보는
                수집하지 않습니다. 다만 이용자의 적법한 동의가 있거나 법령의
                규정에 의한 경우는 예외로 합니다. 수집방법 : 홈페이지를 통한
                회원 가입 및 변경, 문의 메일, 전화 상담 등
              </S.ListItem>
            </S.UList>
          </S.ListItem>
          <S.ListItem>
            수집방법: 홈페이지를 통한 회원 가입 및 변경, 문의 메일, 전화 상담 등
          </S.ListItem>
        </S.OList>
      </S.Box>
      <S.Box>
        <S.SubTitle>5. 개인정보의 이용 및 제공</S.SubTitle>
        <S.OList>
          <S.ListItem>
            회사에서는 수집한 개인정보를 다음의 목적을 위해 이용할 수 있습니다.
            <S.UList>
              <S.ListItem>
                홈페이지 회원관리: 회원제 서비스 이용 등에 따른 본인확인,
                개인식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사
                확인, 가입 및 가입횟수 제한, 고지사항 전달, 상담처리 등
              </S.ListItem>
              <S.ListItem>이메일 서비스 제공</S.ListItem>
              <S.ListItem>설문조사와 이벤트를 위한 이용</S.ListItem>
              <S.ListItem>
                컨텐츠 및 회원 맞춤형 서비스 제공, 서비스 구매 및 요금결제,
                금융거래 본인인증 및 금융서비스
              </S.ListItem>
              <S.ListItem>
                신규서비스(콘텐츠) 개발 및 특화, 이벤트 등 광고성 정보 전달,
                통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악,
                회원의 서비스 이용에 대한 통계
              </S.ListItem>
            </S.UList>
          </S.ListItem>
          <S.ListItem>
            회원이 제공한 개인정보는 당해 회원의 동의 없이는 회사가 이미 고지
            또는 명시한 목적범위 이외로 이용하거나 제3자에게 제공할 수 없습니다.
            다만, 아래의 경우는 예외로 합니다.
            <S.UList>
              <S.ListItem>
                수사를 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 등의
                요구가 있는 경우
              </S.ListItem>
              <S.ListItem>
                금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에
                관한 법률, 전기통신기본법, 전기통신사업법, 지방세법,
                소비자보호법, 한국은행법, 형사소송법 등 법령에 특별한 규정이
                있는 경우
              </S.ListItem>
              <S.ListItem>
                법령의 규정에 근거하여 통계목적, 학술연구, 시장조사 등에 특정
                개인을 식별할 수 없는 형태로 개인정보를 제공하는 경우
              </S.ListItem>
            </S.UList>
          </S.ListItem>
          <S.ListItem>
            회원의 동의를 받아야 하는 경우에도 회사는 다음 사항을 미리
            명시하거나 고지해야 하며 회원은 이에 대해 거절할 권리를 가지며, 또한
            언제든지 동의를 철회할 수 있습니다.
            <S.UList>
              <S.ListItem>
                개인정보보호 책임자의 신원(소속, 성명 및 전화번호)
              </S.ListItem>
              <S.ListItem>개인정보의 수집목적 및 이용목적</S.ListItem>
              <S.ListItem>수집하고자 하는 개인정보 항목</S.ListItem>
              <S.ListItem>
                수집하고자 하는 개인정보의 보유기간 및 이용기간
              </S.ListItem>
              <S.ListItem>제3자에 대한 정보제공 관련사항</S.ListItem>
              <S.ListItem>
                회원의 동의철회권과 자신의 정보에 대한 열람•오류정정 요구권 및
                그 행사방법
              </S.ListItem>
              <S.ListItem>
                기타 정보통신망이용촉진등에 관한 법률 등 관계법령이 규정한 사항
              </S.ListItem>
            </S.UList>
          </S.ListItem>
          <S.ListItem>
            이메일 등 개인정보가 부정확하여 서비스의 품질에 지장을 초래하는
            경우에는 홈페이지 운영자가 해당정보를 삭제하거나 수정할 수 있습니다.
          </S.ListItem>
          <S.ListItem>
            회원이 온라인 상에서 자발적으로 제공하는 개인정보는 제3자가 수집하여
            사용할 수 있으며 이에 대한 책임은 회원에게 있습니다.
          </S.ListItem>
          <S.ListItem>
            또한 업무수행을 위해 개인정보를 확인하는 것은 접근 권한이 있는
            업무담당자(개인정보취급자)에 한정되어 있습니다.
          </S.ListItem>
        </S.OList>
      </S.Box>
      <S.Box>
        <S.SubTitle>6. 개인정보에 대한 회원의 권리 및 행사방법</S.SubTitle>
        <S.OList>
          <S.ListItem>
            이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를
            행사할 수 있습니다.
            <S.UList>
              <S.ListItem>개인정보 열람요구</S.ListItem>
              <S.ListItem>오류 등이 있을 경우 정정 요구</S.ListItem>
              <S.ListItem>삭제요구</S.ListItem>
              <S.ListItem>취급정지 요구</S.ListItem>
            </S.UList>
          </S.ListItem>
          <S.ListItem>
            제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,
            모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이
            조치하겠습니다.
          </S.ListItem>
          <S.ListItem>
            이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
            회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
            제공하지 않습니다.
          </S.ListItem>
          <S.ListItem>
            만 14세 미만 아동의 경우, 제1항에 따른 권리 행사는 이용자의
            법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.
            이 경우, 법정대리인은 이용자의 모든 권리를 가집니다.
          </S.ListItem>
          <S.ListItem>
            회원이 온라인 상에서 자발적으로 제공하는 개인정보는 제3자가 수집하여
            사용할 수 있으며 이에 대한 책임은 회원에게 있습니다.이용자는
            정보통신망법, 개인정보보호법 등 관계법령을 위반하여 회사가 취급하고
            있는 이용자 본인이나 타인의 개인정보 및 사생활을 침해하여서는
            아니됩니다.
          </S.ListItem>
        </S.OList>
      </S.Box>
      <S.Box>
        <S.SubTitle>7. 개인정보의 보유 및 이용기간</S.SubTitle>
        <S.Text>
          원칙적으로 개인정보 수집 및 이용목적이 달성된 후(회원탈퇴 및 이메일
          서비스 해지)에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 사유에
          대해서는 회사 및 관계법령에 명시되어 있는 기간 동안 보유합니다.
        </S.Text>
        <S.OList>
          <S.ListItem>
            회사 내부 방침에 의한 정보보유 사유
            <S.UList>
              <S.ListItem>
                이메일 주소
                <S.UList>
                  <S.ListItem>
                    보존 이유 : 불량회원의 부정이용방지와 비인가 사용방지 및
                    서비스 이용의 혼선 방지
                  </S.ListItem>
                  <S.ListItem>보존 기간 : 1년</S.ListItem>
                </S.UList>
              </S.ListItem>
            </S.UList>
          </S.ListItem>
          <S.ListItem>
            관계법령에 의한 정보보유 사유
            <S.UList>
              <S.ListItem>
                웹사이트 방문기록
                <S.UList>
                  <S.ListItem>보존 이유 : 통신비밀보호법</S.ListItem>
                  <S.ListItem>보존 기간 : 3개월</S.ListItem>
                </S.UList>
              </S.ListItem>
              <S.ListItem>
                본인확인에 관한 기록
                <S.UList>
                  <S.ListItem>
                    보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
                  </S.ListItem>
                  <S.ListItem>보존 기간 : 6개월</S.ListItem>
                </S.UList>
              </S.ListItem>
              <S.ListItem>
                표시/광고에 관한 기록
                <S.UList>
                  <S.ListItem>
                    보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
                  </S.ListItem>
                  <S.ListItem>보존 기간 : 6개월</S.ListItem>
                </S.UList>
              </S.ListItem>
              <S.ListItem>
                계약 또는 청약철회 등에 관한 기록
                <S.UList>
                  <S.ListItem>
                    보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
                  </S.ListItem>
                  <S.ListItem>보존 기간 : 5년</S.ListItem>
                </S.UList>
              </S.ListItem>
              <S.ListItem>
                대대금결제 및 재화 등의 공급에 관한 기록
                <S.UList>
                  <S.ListItem>
                    보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
                  </S.ListItem>
                  <S.ListItem>보존 기간 : 5년</S.ListItem>
                </S.UList>
              </S.ListItem>
              <S.ListItem>
                소비자의 불만 또는 분쟁처리에 관한 기록
                <S.UList>
                  <S.ListItem>
                    보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
                  </S.ListItem>
                  <S.ListItem>보존 기간 : 3년</S.ListItem>
                </S.UList>
              </S.ListItem>
            </S.UList>
          </S.ListItem>
        </S.OList>
      </S.Box>
      <S.Box>
        <S.SubTitle>8. 개인정보 보호에 대한 기술적 관리적 대책</S.SubTitle>
        <S.OList>
          <S.ListItem>
            회사는 개인정보가 누출되지 않도록 기술적•관리적 대책을 강구하는 데
            최선을 다합니다.
          </S.ListItem>
          <S.ListItem>
            회사는 이용자의 개인정보를 취급함에 있어서 개인정보가 분실, 도난,
            누출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 최선을 다하고
            있습니다. 이를 위하여 비밀번호 등을 이용한 보안장치, 백신 프로그램을
            이용한 컴퓨터바이러스 방지장치, 암호알고리즘 등을 이용하여 네트워크
            상에 개인정보를 안전하게 전송할 수 있는 보안장치, 침입차단시스템
            등을 이용한 접근통제장치, 기타 안정성 확보를 위하여 필요한 기술적
            장치를 확보하는 등의 기술적 대책을 강구하고 있습니다.
          </S.ListItem>
          <S.ListItem>
            회사는 개인정보 접근이 가능한 직원을 최소한으로 제한하고, 본 정책의
            준수 및 기타 개인정보 보호를 위한 지속적인 교육을 실시하고 있습니다.
            또한 개인정보 접근을 위한 운영자 비밀번호를 정기적으로 갱신하여
            불법적인 정보유출을 막기 위해 최선을 다하고 있습니다.
          </S.ListItem>
        </S.OList>
      </S.Box>
      <S.Box>
        <S.SubTitle>9. 쿠키(Cookie)의 운영에 관한 사항</S.SubTitle>
        <S.OList>
          <S.ListItem>
            회사는 회원에 대한 정보를 저장하고, 수시로 찾아내는 “쿠키(Cookie)"를
            사용합니다. 쿠키는 홈페이지가 회원의 컴퓨터 브라우저로 전송하는
            소량의 정보입니다. 회원께서 홈페이지에 접속을 하면 본 회사의
            컴퓨터는 회원의 브라우저에 있는 쿠키의 내용을 읽고, 회원의
            추가정보를 회원의 컴퓨터에서 찾아 접속에 따른 성명, 홈페이지 주소
            등의 추가입력 없이 서비스를 제공할 수 있도록 하고 있습니다.
          </S.ListItem>
          <S.ListItem>
            쿠키는 회원의 컴퓨터는 식별하지만 회원을 개인적으로 식별하지는
            않습니다. 또한 회원께서는 웹브라우저의 옵션을 조정함으로써 모든
            쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나,
            아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
          </S.ListItem>
          <S.ListItem>
            쿠키 설정 거부 방법(인터넷 익스플로러) 웹 브라우저 상단의 도구
            >인터넷 옵션 >개인정보 탭 >개인정보취급 수준에서 설정 이용자가
            사용하시는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용하거나
            쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수
            있습니다. 단, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부
            서비스의 이용에 제한이 생길 수 있음을 양지하시기 바랍니다.
          </S.ListItem>
        </S.OList>
      </S.Box>
      <S.Box>
        <S.SubTitle>10. 광고성 정보전송</S.SubTitle>
        <S.Text>
          회사는 회원의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를
          전송하지 않습니다. 회사 메일링 서비스는 보다 나은 서비스 제공을 위해
          뉴스레터나 서비스 정보 안내, 설문조사와 이벤트를 실시할 수 있으며
          수신거부의 의사표현을 용이하게 할 수 있도록 하단에 표시하고 있습니다.
          이때 통계 및 경품 발송을 위하여 수집한 최소한의 개인정보는 경품
          발송/반송(종료 후 1개월) 이후에 폐기합니다.
        </S.Text>
      </S.Box>
      <S.Box>
        <S.SubTitle>11. 개인정보 보호 의무 및 책임자 공시</S.SubTitle>
        <S.Text>
          회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보
          보호담당자를 지정하고 있습니다. 개인정보 보호담당자는 다음과 같습니다.
        </S.Text>
        <S.UList>
          <S.ListItem>성명: 김 승 연</S.ListItem>
          <S.ListItem>연락처: 010-9972-1055</S.ListItem>
          <S.ListItem>이메일: loycord.ceo@gmail.com</S.ListItem>
        </S.UList>
      </S.Box>
    </S.Container>
  );
}
