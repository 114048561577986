import React, { useContext } from 'react';
import styled from 'styled-components';
import { rotate } from '../../styles/keyframes';
import colors from '../../styles/themes/colors';

import { Context as LoadingContext } from './context';

export default function LoadingScreen() {
  const loading = useContext(LoadingContext);

  if (loading.state.state !== 'pending') return null;

  return (
    <Container>
      <Spinner />
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ backColor }) => backColor || colors.PRIMARY};
`;

const Spinner = styled.div`
  border: 1rem solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 2s linear infinite;
`;
